<script setup>
import { reactive, computed, ref  } from "vue";
import { storeToRefs } from 'pinia';

import { useAuthStore, useUsersStore, useTodosStore } from '@/stores';

// const authStore = useAuthStore();
// const { user: authUser } = storeToRefs(authStore);

const usersStore = useUsersStore();
const { users } = storeToRefs(usersStore);

const todosStore = useTodosStore();
const { todos } = storeToRefs(todosStore);

const initialState = {
	title: '',
	description: ''
};

const form = reactive({ ...initialState })

function onSubmit() {
    todosStore.addTodo(form).then(() => {
      Object.assign(form, initialState); //сброс полей формы
    })
}


const items = ref(Array.from({ length: 100000 }).map((_, i) => `Item #${i}`));

usersStore.getAll();
todosStore.getAll()

</script>

<template>
    <div class="home p-4 m-auto max-w-screen-sm">
        <!-- <h1>Hi {{users?.name}}! - {{users?.id}}</h1> -->

        <form class="mb-5 w-full" @submit.prevent="onSubmit">
            <div class="mb-5">
                <input 
                    name="title" 
                    v-model="form.title"
                    type="text"
                    class="shadow appearance-none border rounded w-full py-2 px-3 mr-4 text-grey-darker"
                    placeholder="Название"
                    required />
            </div>
            <div class="mb-5">
                <textarea 
                    name="description"
                    v-model="form.description"
                    class="shadow appearance-none border rounded w-full py-2 px-3 mr-4 text-grey-darker"
                    placeholder="Описание"
                ></textarea>
            </div>
            <div class="mb-5">
                <button
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                    Добавить
                </button><br>
            </div>
        </form>

        <template v-if="todos.length > 0">
            <div class="flex mb-2 min-h-11 items-center relative w-full" v-for="todo in todos" :key="todo.id">
                <div v-if="!todo.is_active" class="absolute w-full h-full bg-sky-500/30"></div>
                <div class="w-10/12 text-grey-darkest">
                    <p class="text-lg font-bold [font-size:_clamp(1em,5vw,1.5em)]">{{ todo.title }} </p>
                    <p class="text-xs">{{ todo.description }}</p>
                </div>
                <span @click="todosStore.done({id:todo.id, active: !todo.is_active})">
                    <span class="todo__action todo__action_complete" v-if="todo.is_active"></span>
                    <span class="todo__action todo__action_restore" v-else></span>
                </span> 
                <span class="todo__action todo__action_delete " @click="todosStore.delete({id:todo.id})"></span>
            </div>
            <div v-if="users.loading" class="spinner-border spinner-border-sm"></div>
            <div v-if="users.error" class="text-danger">Error loading users: {{users.error}}</div>
        </template>
    </div>
</template>
<style scoped lang="scss">
.home {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}

.todo {
    &__action {
        position: absolute;
        top: 22px;
        right: 15px;
        height: 32px;
        width: 32px;
        border-radius: 4px;
        transform: translateY(-50%);
        background-color: #80deea;
        opacity: 0.5;
        transition: top 0.3s ease-in;
        cursor: pointer;
        &_restore {
            right: 52px;
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z'%3E%3C/path%3E%3C/svg%3E");
            }
        }
        &_complete{ 
            right: 52px;
            background-color: #66bb6a;
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
            }
        }

        &_delete {
            background-color: #e57373;
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23fff' d='M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z'%3E%3C/path%3E%3C/svg%3E");
            }
        }
        &:before {
            content: '';
            position: absolute;
            top: 6px;
            right: 6px;
            width: 20px;
            height: 20px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
</style>