import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';

const baseUrl = `${import.meta.env.VITE_API_URL}/todos`;

export const useTodosStore = defineStore({
    id: 'todos',
    state: () => ({
        todos: []
    }),
    actions: {
        async getAll() {
            this.todos = { loading: true };
            fetchWrapper.get(baseUrl)
            .then(todos => this.todos = todos)
            .catch(error => this.todos = { error })
        },

        async addTodo(data) {
            fetchWrapper.post(baseUrl+'/create', data)
            .then(todo => this.todos.unshift(todo))
            .catch(error => this.todos = { error })
        },
        /**
         * id
         * active
         * @param {*} data 
         */
        async done(data) {
            fetchWrapper.post(baseUrl+'/done', data)
            .then(() => {
                const todo = this.todos.find(el => el.id == data.id)
                if(todo) todo.is_active = data.active
            })
            .catch(error => this.todos = { error })
        },

        /**
         * id
         * @param {*} data 
         */
        async delete(data) {
            fetchWrapper.post(baseUrl+'/delete', data)
            .then(() => {
                const index = this.todos.findIndex(el => el.id == data.id)
                this.todos.splice(index, 1)
            })
            .catch(error => this.todos = { error })
        },

    }
});
