import { createRouter, createWebHistory } from "vue-router";

import { useAuthStore } from '@/stores';
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue';
import RegisterView from '@/views/RegisterView.vue';
import ForgotPasswordView from '@/views/ForgotPasswordView.vue';
import ResetPasswordView from '@/views/ResetPasswordView.vue';

const routes = [
    {
      path: "/",
      component: HomeView
    },
    {
      path: "/login",
      component: LoginView
    },
    {
      path: "/register",
      component: RegisterView
    },
    {
      path: "/forgot-password",
      component: ForgotPasswordView
    },
    {
      path: "/reset-password/:token",
      component: ResetPasswordView
    },
  ]

const router = createRouter({
  history: createWebHistory(), //import.meta.env.VITE_APP_ENV
  routes,
});

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register', '/forgot-password', '/reset-password'];
  const authRequired = publicPages.findIndex(el => to.path.includes(el));
  
  const auth = useAuthStore();

  if (authRequired < 0 && !auth.user) {
      auth.returnUrl = to.fullPath;
      return '/login';
  }
});

export default router;